import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    currentPage: '',  // Default is empty, will be set after login
    userData: {},  // Contains user info like role, site, etc.
    selectedLocation: 'All Locations',  // Default for Admin/Head or custom for User
    selectedSensor: 'All Sensors',  // Default for Admin/Head or specific for User
  },
  reducers: {
    setAppSliceState: (state, { payload }) => {
      console.log(payload)
      // If changing more than one key/value pair
      if (Array.isArray(payload[0])) {
        payload.forEach(([key, value]) => {
          state[key] = value;
        });
      }
      // If only changing one set
      else {
        const [key, value] = payload;
        state[key] = value;
      }
    },
    // Additional action to manage role-based dashboard selection
    setRoleBasedDashboard: (state) => {
      const { role, site } = state.userData;
      console.log("role",role,"site",site)
      if (role === 'Admin') {
        state.selectedLocation = state.selectedLocation || 'All Locations';
        state.selectedSensor = state.selectedSensor || 'All Sensors';
      } else if (role === 'Head') {
        state.selectedLocation = state.selectedLocation || 'All Locations';
        state.selectedSensor = state.selectedSensor || 'All Sensors'; // Default for Head can be customized
      } else if (role === 'User') {
        // User-specific case: if no location or sensor is selected, fallback to a specific sensor or location
        state.selectedLocation = state.selectedLocation || site || 'Specific Location';  // User's site if available
        state.selectedSensor = state.selectedSensor || 'Specific Sensor';  // Default user sensor selection
      }
    },
  },
});

export const {
  actions: { setAppSliceState, setRoleBasedDashboard },
} = appSlice;

export const selectApp = (state) => state.app;

export default appSlice.reducer;
