import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    email: '',
    isRememberMe: false,
    password: '',
    pageType: 'login',  
    username: '',
    error: null, 
    loading: false,  
    authToken: '',  
  },
  reducers: {
    setLoginSliceState: (state, { payload }) => {
      if (Array.isArray(payload[0])) {
        payload.forEach(([key, value]) => {
          state[key] = value;
        });
      }
      else {
        const [key, value] = payload;
        state[key] = value;
      }
    },
    setLoginError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;  
    },
    setLoginLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setAuthToken: (state, { payload }) => {
      state.authToken = payload;
    },
    clearLoginState: (state) => {
      state.email = '';
      state.password = '';
      state.username = '';
      state.error = null;
      state.loading = false;
      state.authToken = '';
    },
  },
});

export const {
  actions: {
    setLoginSliceState,
    setLoginError,
    setLoginLoading,
    setAuthToken,
    clearLoginState,
  },
} = loginSlice;

export const selectLogin = (state) => state.login;

export default loginSlice.reducer;
