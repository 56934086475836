import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon, LatLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import "./MapPage.css";
import PropTypes from "prop-types";

function MapPage({ locationProp }) {
  if (!locationProp || !Array.isArray(locationProp)) {
  //  console.error("Invalid or missing locationProp. Expected an array of coordinates.");
    return <div>No valid location provided</div>;
  }

  // Define a custom icon for the marker
  const markerIcon = new Icon({
    iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  // Extract coordinates for LatLngBounds (you need only the coordinates)
  const bounds = new LatLngBounds(locationProp.map(loc => loc.coordinates));

  //console.log("Bounds:", bounds); // Ensure bounds are being calculated

  return (
    <MapContainer
      className="map-container"
      bounds={bounds} // Set bounds so the map focuses on all markers
      zoom={10} // Adjust zoom level to ensure visibility of all markers
      scrollWheelZoom={true}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
      />
      {locationProp.map((location, index) => (
        <Marker key={index} position={location.coordinates} icon={markerIcon}>
          <Popup>
            <div>
              <h4>{location.name}</h4>
              <p>Lat: {location.coordinates[0]}</p>
              <p>Lng: {location.coordinates[1]}</p>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

// Prop validation for better debugging
MapPage.propTypes = {
  locationProp: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
    })
  ).isRequired,
};

export default MapPage;
