import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaListAlt, FaTachometerAlt, FaBars, FaSlidersH, FaClock, FaListOl, FaArrowsAlt, FaEject, FaThermometerHalf, FaFileExcel } from "react-icons/fa";
import sidebarBg from "components/account/assets/sidebar-assets/bg1.jpg"
import {VscSymbolClass} from "react-icons/vsc";
import { FaHeart } from 'react-icons/fa';
import { Store } from "components/account/flux-tools";
import {GiAerodynamicHarpoon} from 'react-icons/gi'
import {BsAlarmFill} from 'react-icons/bs'
import { Link } from 'react-router-dom';


class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false,
      collapsed: true,
      toggled: false,
      sidebarNavItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  componentDidMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems()
    });
  }

  handleToggleSidebar = (value) => {
    this.setState({toggled: value})
  };

  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      
      this.state.menuVisible && "open"
    );

    return (
      // <Col
      //   // tag="aside"
      //   className={classes}
      //   style={{backgroundColor: 'rgba(250, 250, 250, 0.5)',}}
      // >

<main>
<div className="btn-toggle" onClick={() => this.setState({toggled: true, collapsed: false})} style={{color: 'teal'}}>
        <FaBars />
      </div>

  <ProSidebar
      image={Image ? sidebarBg : false}
      collapsed={this.state.collapsed}
      toggled={this.state.toggled}
      breakPoint="lg"
      onToggle={this.handleToggleSidebar}
      collapsedWidth="0%"
      width="40%"
    style={{zIndex:4000}}
      // className={classes}
      className="mt-0"
    >
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            height:'100%'
          }}
          // onFocus={()=> this.setState({collapsed: !this.state.collapsed})}
          // onBlur={()=> this.setState({collapsed: !this.state.collapsed})}
          // tabIndex="0"
          onClick={()=> this.setState({collapsed: !this.state.collapsed})}
        >
          Third Eye
        </div>
      </SidebarHeader>

      <SidebarContent>
        {/* <Menu iconShape="circle">
          <MenuItem
            icon={<FaTachometerAlt />}
            // suffix={<span className="badge red">new</span>}
          >
            Dashboard
            <Link to={{pathname: "/"}} />
          </MenuItem>
        </Menu> */}
         <Menu iconShape="circle" popperArrow>  
        {/* <MenuItem icon={<FaListAlt />}> Metering</MenuItem> */}
        {/* <SubMenu
            // suffix={<span className="badge yellow">3</span>}
            title='Metering'
            icon={<FaListAlt />}
          > */}
            <MenuItem icon={<GiAerodynamicHarpoon color="cyan" size={20}/>}>Dynamic
              <Link to={{pathname: "/Dynamic"}} />
            </MenuItem>
            <MenuItem icon={<BsAlarmFill color="cyan" size={20}/>}>Alarms
              <Link to={{pathname: "/Alarms"}} />
            </MenuItem>
            <MenuItem icon={<BsAlarmFill color="cyan" size={20}/>}>History
                <Link to={{pathname: "/History"}} />
            </MenuItem>
            {/* <MenuItem icon={<FaListAlt />}>Overview
              <Link to={{pathname: "/Metering"}} />
            </MenuItem> */}

            {/* <MenuItem>Common
              <Link to={{pathname: "/CommonMetering"}} />
            </MenuItem> */}

            {/* <MenuItem >Electrical
              <Link to={{pathname: "/ElectricalStats"}} />  
            </MenuItem> */}

          {/* </SubMenu> */}
          </Menu> 
        {/* <Menu iconShape="circle" popperArrow>  */}
        {/* <SubMenu
            // suffix={<span className="badge yellow">3</span>}
            title='Centralized Control'
            icon={<FaSlidersH />}
          > */}
            {/* <MenuItem>Rooms Overview
            <Link to={{pathname: "/RoomOverview"}} />
            </MenuItem> */}
            {/* <MenuItem>Room Control
            <Link to={{pathname:"/Roomcontrol"}} />
            </MenuItem> */}
            {/* <MenuItem>Common Overview
            <Link to={{pathname:"/Commonoverview"}} />
            </MenuItem> */}
            {/* <MenuItem>Common Control
            <Link to={{pathname:"/Commoncontrol"}} />
            </MenuItem> */}
          {/* </SubMenu> */}
          {/* <MenuItem icon={<FaSlidersH />}> Meters */}
              {/* <Link to={{pathname: "/FloorMetering"}} /> */}
              {/* <Link to={{pathname: "/Meters"}} /> */}
            {/* </MenuItem>
        </Menu> */}
        {/* <Menu iconShape="circle">
          <MenuItem icon={<VscSymbolClass />}>A/C Control
            <Link to = {{pathname:"/Blowers"}} />
          </MenuItem>
        </Menu> */}
        {/* <Menu iconShape="circle"> */}
          {/* <MenuItem icon={<FaThermometerHalf />}><Link to={{pathname:"/HVAC"}} />HVAC</MenuItem> */}
          {/* <MenuItem icon={<FaThermometerHalf />}>HVAC</MenuItem>
        </Menu> */}
        {/* <Menu iconShape="circle">
          <MenuItem icon={<FaPrint color="cyan" size={20}/>}>Reports
            <Link to = {{pathname:"/Reporting"}} />
          </MenuItem>
        </Menu>
        <Menu iconShape="circle">
          <MenuItem icon={<RiFileExcel2Line />}>Reports - 2.0
            <Link to = {{pathname:"/Reports"}} />
          </MenuItem>
        </Menu> */}
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          
            {/* <FaBars onClick={()=> this.setState({collapsed: !this.state.collapsed})} style={{color:this.state.collapsed ? "green":"red"}} /> */}
            
        </div>
      </SidebarFooter>
    </ProSidebar>
      </main>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
