import React from "react";
import { Redirect } from "react-router-dom";
import  DefaultLayout from "components/account/default-dashboard-layout/Default";
import  Unknown  from "components/account/default-dashboard-layout/unknown";
import UserProfileLite from "components/account/UsersProfile/UserProfileLite";
import PasswordReset from "../addUser/resetPassword";
import AddUser from 'components/account/addUser/addUser'
import  Kaurmunda from '../views/Kaurmunda/kaurmunda'
const userRoutes= [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => {
      console.log("Redirecting to /site/kaurmunda...");
      return <Redirect to="/kaurmunda" />;
    },
  },  
  {
    path: "/kaurmunda",
    exact: true,
    layout: DefaultLayout,
    component: Kaurmunda,  // Correctly reference the Kaurmunda component
  },
  {
    path: "/user-profile",
    exact: true,
    layout: DefaultLayout,
    component: UserProfileLite
  },

 
  {
    path: "/add-user",
    layout: DefaultLayout,
    component: AddUser
  },

  {
    path: "/reset-password",
    exact: true,
    layout: DefaultLayout,
    component: PasswordReset
  },
 
];
export default userRoutes;

