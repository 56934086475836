import { Form, Button, FormGroup, FormControl, Card, ControlLabel, InputGroup, Navbar, NavDropdown } from "react-bootstrap";
import { Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { selectApp, setAppSliceState } from 'state/appSlice';
import { selectLogin, setLoginSliceState } from 'state/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import persistentStorage from "utilities/persistentStorage";
import pageConfig from "./utilities/pageConfig";
import submitForm from "./utilities/submitForm";
import Modal from 'react-modal';
import Icon from 'components/login/assets/Icon';
import logo from 'components/login/assets/eye.png';
import { Oval } from "react-loader-spinner";
import '../account/all-dashboard-styles/Form.css'

const Login = () => {
    const dispatch = useDispatch();
  
    const {
      email,
      isRememberMe,
      pageType,
      password,
      username
    } = useSelector(selectLogin);
    const { currentPage } = useSelector(selectApp);
  
    const [showNotice, setShowNotice] = useState(false);
    const [notice, setNotice] = useState({ header: '', message: '' });
    const [showLoader, setShowLoader] = useState(false);
  
    
    useEffect(() => {
      const rememberUser = persistentStorage.getLocalValue('rememberUser');
      const usernameData = persistentStorage.getLocalValue('username');
  
      if (rememberUser) {
        if (usernameData) {
          dispatch(setLoginSliceState([
            ['isRememberMe', true],
            ['username', usernameData]
          ]));
        }
  
        else dispatch(setLoginSliceState(['isRememberMe', true]));
      }
  
      if (notice.header) {
        setShowNotice(true);
        setShowLoader(false);
      }
  
      else setShowNotice(false);
    }, [notice]);
  
    const toggleForgotPassword = () => {
      dispatch(setLoginSliceState(['pageType', 'passwordReset']));
    };
  
    const toggleLoginSignup = () => {
      const newPageType = pageType === 'login' ? 'signUp' : 'login';
      dispatch(setLoginSliceState(['pageType', newPageType]));
    };
    const toggleRememberMe = () => {
      persistentStorage.setLocalValue('rememberUser', !isRememberMe);
      dispatch(setLoginSliceState(['isRememberMe', !isRememberMe]));
    };
    const updateEmail = (event) => {
      const { target: { value } } = event;
      dispatch(setLoginSliceState(['email', value]));
    };
    const updatePassword = (event) => {
      const {
        target: { value }
      } = event;
      dispatch(setLoginSliceState(['password', value]));
    };
    const updateUsername = (event) => {
      const {
        target: { value }
      } = event;
      dispatch(setLoginSliceState(['username', value]));
    };
    const { currentPageText, newPageText } = pageConfig(pageType);
    const submitInput = useRef(null);
    const handleSubmit = (event) => {
      event.preventDefault();
      setShowLoader(true);
      submitForm(
        pageType,
        email,
        password,
        username,
        setNotice
      ).then((response) => {
        if (currentPage === 'login' && pageType === 'login') {
          const hasResponseData = Boolean(response && response.data !== null);
          console.log(response)
          if (hasResponseData && !response.data.isEmailVerified) {
            setNotice({
              header: 'Verify email address',
              message: "You must first verify your email before you're able to log in."
            });
          }
          else if (hasResponseData) {
            if (persistentStorage.getLocalValue('rememberUser')) {
              persistentStorage.setLocalValue('username', username);
            }
            else persistentStorage.deleteLocalItem('username');
            persistentStorage.setSessionValue('userData', response.data);
            if(response.data.role == "Admin"){
            dispatch(
              setAppSliceState([ ['currentPage', 'account'], ['userData', response.data] ])
            );
            }
            else if(response.data.role == "Head"){
              dispatch(
                setAppSliceState([ ['currentPage', 'account'], ['userData', response.data] ])
              );
            }
            else if(response.data.role == "User"){
              dispatch(
                setAppSliceState([ ['currentPage', 'account'], ['userData', response.data] ])
              );
            }
            else{
              setNotice({
                header: 'You are not Authorised',
                message: "Please contact the support team"
              });
            }
          }
  
        }
      });
    };
  
    return (

        <div className='form-container'>
          <div className="loader"><Oval color="#00BFFF" height={80} width={80} visible={ showLoader || false } /></div>
          <div className='form-content-left'>
            <img className='form-img h-50' src={logo} />
          </div>
        
         <div className='form-content-right'>
          <form onSubmit={ handleSubmit } className="form" noValidate>
          <div className="main-text">
            THIRD EYE
          </div>
          <Container >
            <Icon pageType={ pageType } className="icon" />
            <div className="content-text text-center" >{currentPageText}</div>
            <div>
              {
                pageType === 'passwordReset' ? (
                  <InputGroup className="mb-3">
                    
                    <FormControl
                      onChange= {updateEmail}
                      placeholder="Email ID"
                      required
                      type="email"
                      value={email || ''}
                      
                    />
                  </InputGroup>
                ) : null
              }
              {
                pageType !== 'passwordReset' ? (
                  <Fragment>
                    <InputGroup>
                      <FormControl
                        onChange={updateUsername}
                        placeholder={ "Username or Email" }
                        required
                        value={ username || '' }
                        variant="large"
                      />
                    </InputGroup>
  
                    <InputGroup className="mb-3">
                      
                      <FormControl
                        className="mt-2"
                        autoComplete="on"
                        minLength="6"
                        onChange={ updatePassword }
                        placeholder="Password"
                        required
                        type="password"
                        value={ password || '' }
                        variant="large"
                      />
                    </InputGroup>
                  </Fragment>
                ) : null
              }
            </div>
  
            <Button
              className="btn-info mx-auto"
              onClick={ () => submitInput.current.click() }
              variant="large"
            >
              <input
               className="main-input"
                ref={ submitInput }
                type="submit"
              />
              { currentPageText }
            </Button>
            
            <div className={ pageType === 'login'? 'login-class' : '' }>
              <a href="#submit" onClick={ toggleForgotPassword } style={{fontSize: 12, color: "blue"}}>
                Forgot password?
              </a>
  
            </div>
          </Container>
        </form>
  
        <div>
        <Modal isOpen={showNotice || false} className="Modal" style={{minHeight:"50%", width:"50%", alignItems:"center"}} ariaHideApp={false}>
        <p style={{marginTop: "18%", textAlign:"center"}}>{notice.message}</p>
        <div className="d-flex justify-content-center"><Button onClick={() => setShowNotice(false) }>Okay</Button></div>
        </Modal>
        </div>
        </div>
        </div>
        
    );
  };
  export default Login;
  