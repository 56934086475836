import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaListAlt, FaChartLine, FaChartArea, FaTachometerAlt ,FaHistory, FaBars,FaPrint, FaSlidersH, FaClock, FaListOl, FaArrowsAlt, FaEject, FaThermometerHalf, FaFileExcel } from "react-icons/fa";
import sidebarBg from "components/account/assets/sidebar-assets/bg1.jpg"
import {VscSymbolClass} from "react-icons/vsc";
import {RiFileExcel2Line} from "react-icons/ri";
import { GiAerodynamicHarpoon } from "react-icons/gi";
import {BsAlarmFill} from 'react-icons/bs';
import { Store } from "components/account/flux-tools";
import { Link } from 'react-router-dom';
import { useState,useEffect } from "react";
const MainSidebar = ({ hideLogoText }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [sidebarNavItems, setSidebarNavItems] = useState(Store.getSidebarItems());

  useEffect(() => {
    const handleStoreChange = () => {
      setMenuVisible(Store.getMenuState());
      setSidebarNavItems(Store.getSidebarItems());
    };

    Store.addChangeListener(handleStoreChange);
    return () => {
      Store.removeChangeListener(handleStoreChange);
    };
  }, []);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <ProSidebar
      image={sidebarBg ? sidebarBg : false}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
      collapsedWidth="80px"
      width="250px"
      style={{
        zIndex: 1000,
        height: "100vh",
        position: "sticky",
        top: 0,
      }}
    >
      {/* Sidebar Header */}
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          onClick={() => setCollapsed(!collapsed)}
        >
          {hideLogoText ? null : "Third Eye"}
        </div>
      </SidebarHeader>

      {/* Sidebar Content */}
      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<GiAerodynamicHarpoon color="cyan" size={20} />}>Dynamic
            <Link to="/Dynamic" />
          </MenuItem>
          <MenuItem icon={<BsAlarmFill color="cyan" size={20} />}>Alarms
            <Link to="/Alarms" />
          </MenuItem>
          <MenuItem icon={<FaHistory color="cyan" size={20} />}>History
            <Link to="/History" />
          </MenuItem>
        </Menu>
      </SidebarContent>

      {/* Sidebar Footer */}
      <SidebarFooter style={{ textAlign: "center" }}>
        <div className="sidebar-btn-wrapper" style={{ padding: "20px 24px" }}>
          <span
            style={{ cursor: "pointer", color: collapsed ? "green" : "red" }}
            onClick={() => setCollapsed(!collapsed)}
          >
            Toggle Sidebar
          </span>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

MainSidebar.propTypes = {
  hideLogoText: PropTypes.bool,
};

MainSidebar.defaultProps = {
  hideLogoText: false,
};

export default MainSidebar;
