import React from 'react';
import { FaBolt } from 'react-icons/fa'; // Import the lightning icon from react-icons
import './lightning.css'; // Ensure this CSS file is included
import lightningImage from '../../../assets/cmce/CMCE1.png'; // Adjust path as per your project structure

function LightningPage({ location, lastReading, onDismiss }) {
  return (
    <div className="lightning-page-container">
      <div className="rain"></div> {/* Add rain background */}
      <div className="lightning-content">
        <div className="lightning-icon">
          <div className="lightning-background">
            <img src={lightningImage} alt="Lightning Protector" className="lightning-image" />
          </div>
        </div>
        <div className="text-content">
          <h2 className="alert-heading">
            {/* Lightning icon with glow */}
            Lightning Strike Detected <FaBolt className="lightning-glow" size={40} />
          </h2>
          <p>Location: <strong>{location}</strong></p>
          <p>Last Reading: <strong>{lastReading} mA</strong></p>
          <p>Lightning event detected in your area, but your device is safeguarded!</p>
          <button className="dismiss-button" onClick={onDismiss}>Dismiss</button>
        </div>
      </div>
    </div>
  );
}

export default LightningPage;
