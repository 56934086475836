import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "components/account/menu-header-footer-layout/Header-Components/HeaderLayout/header-left-layout";
// import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "components/account/menu-header-footer-layout/Footer/MainFooter";
// import bellagioFront from "../../login/assets/backgroundImages/bellagioFront.jpg";



const stylesnew = {
  header: {
    // backgroundImage: `url(${ bellagioFront })`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: "cover",
    // backgroundColor: "green",
    // position: 'sticky',
    height: '100%',
    // pa
    
    
  },
  content: {
    height: '100%',
    // width: '100%',
    backgroundColor: 'rgba(250, 250, 250, 0.4)',
  },
}
const DefaultLayout = ({ children, noNavbar, noFooter }) => (
  <Container fluid>
    <Row >
      {/* <MainSidebar /> */}
      {/* <Col
        className="main-content p-0"
        lg={{ size: 12, offset: 0}}
        md={{ size: 9, offset: 3 }}
        sm="12"
        tag="main"
        style={{marginLeft: "0%"}}
      > */}
      <div className="main-content p-0" style={{width: "100%"}}>
        {!noNavbar && <MainNavbar />}
        <div style={stylesnew.header}>
        <div style={stylesnew.content}>
        {children}
        </div>
        </div>
        {!noFooter && <MainFooter/>}
      </div>
      {/* </Col> */}
    </Row>
  </Container>
);

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
