import React from "react";
import { Redirect } from "react-router-dom";
import  DefaultLayout from "components/account/default-dashboard-layout/Default";
import  Unknown  from "components/account/default-dashboard-layout/unknown";
import UserProfileLite from "components/account/UsersProfile/UserProfileLite";
import PasswordReset from "../addUser/resetPassword";
import Dynamic from "../views/Dynamic/Dynamic";
import AddUser from 'components/account/addUser/addUser'
const adminRoutes= [
  {
    path:"/",
    exact:true,
    layout: DefaultLayout,
    component: () => <Redirect to="/Dynamic" />
  },
  {
    path:"/Dynamic",
    exact:true,
    layout: DefaultLayout,
    component: Dynamic
  },
 
  {
    path: "/user-profile",
    exact: true,
    layout: DefaultLayout,
    component: UserProfileLite
  },

 
  {
    path: "/add-user",
    layout: DefaultLayout,
    component: AddUser
  },

  {
    path: "/reset-password",
    exact: true,
    layout: DefaultLayout,
    component: PasswordReset
  },
 
];
export default adminRoutes;

