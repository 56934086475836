import React, { useEffect, useState } from 'react';
import { selectApp, setAppSliceState } from 'state/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import Account from 'components/account/Account';
import Login from 'components/login/Login';
import PropTypes from 'prop-types';
import { checkLoginStatus } from 'utilities/requests';
import persistentStorage from 'utilities/persistentStorage';

const App = () => {
  const { currentPage, userData, selectedLocation, selectedSensor } = useSelector(selectApp);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    const storedUserData = persistentStorage.getSessionValue('userData');

    if (storedUserData) {
      checkLoginStatus(
        storedUserData.idToken,
        (response) => {
          loginSuccessCallback(response, dispatch, storedUserData);
          setIsLoading(false); // Set loading to false after success
        },
        () => {
          loginErrorCallback(dispatch);
          setIsLoading(false); // Set loading to false after error
        }
      );
    } else {
      dispatch(setAppSliceState(['currentPage', 'login']));
      setIsLoading(false); // Set loading to false if no user data
    }
  }, [dispatch]);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loader while checking login status
  }

  return (
    <Page
      currentPage={currentPage}
      userData={userData}
      selectedLocation={selectedLocation}
      selectedSensor={selectedSensor}
    />
  );
};

function Page({ currentPage, userData, selectedLocation, selectedSensor }) {
  const { role, site, Type } = userData || {};

  if (role && ['Admin', 'Head', 'User'].includes(role)) {
    return (
      <Account
        role={role}
        location={site || ''}
        sensor={Type || ''}
      />
    );
  }

  return <Login />;
}

function loginSuccessCallback(response, dispatch, userData) {
  const { role } = response.data.users[0];

  dispatch(setAppSliceState([
    ['currentPage', 'account'],
    ['userData', userData],
    ['selectedLocation', 'All Locations'],
    ['selectedSensor', 'All Sensors'],
  ]));

  // Additional role-specific logic can be added here if needed
  console.log(`User with role ${role} logged in successfully.`);
}

function loginErrorCallback(dispatch) {
  persistentStorage.deleteSessionItem('userData');
  dispatch(setAppSliceState(['currentPage', 'login']));
  console.error('Login status check failed. Redirecting to login page.');
}

Page.propTypes = {
  currentPage: PropTypes.string,
  userData: PropTypes.shape({
    role: PropTypes.string,
    site: PropTypes.string,
    Type: PropTypes.string,
  }),
  selectedLocation: PropTypes.string,
  selectedSensor: PropTypes.string,
};

Page.defaultProps = {
  currentPage: null,
  userData: {},
  selectedLocation: null,
  selectedSensor: null,
};

export default App;