import React from "react";
import LocationBlock from "./LocationBlock/LocationBlock"; // Import the new LocationBlock component

const locations = [
  { coordinates: [22.272528, 84.765694], name: "Kuanrmunda Site CMCE 24106", fieldNumber: 1 },
];

function Dynamic() {
  return (
    <div className="dyn-main">
      {locations.map((location, index) => (
        <LocationBlock
          key={index}
          locationCoordinates={[location]} // Pass array with coordinates and name for each location
          locationName={location.name}
          fieldNumber={location.fieldNumber}  // Pass the dynamic field number
        />
      ))}
    </div>
  );
}

export default Dynamic;
