import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler, // Import Filler plugin
} from "chart.js";
import './ChartPage.css';

// Register the required components, including Filler plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function ChartPage({ fieldNumber, acolor }) {
//  console.log(acolor[fieldNumber]); // Logging the color for debugging

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from ThingSpeak API
        const response = await fetch(
          "https://api.thingspeak.com/channels/2740476/feeds.json?api_key=IN5SG9ZMXCBXQD5L&results=90"
        );
        const data = await response.json();
      //  console.log("API Response:", data); // Check the structure of the response

        if (data.feeds && data.feeds.length > 0) {
          const labels = [];
          const fieldData = [];

          // Process the feeds array in reverse to handle the most recent values first
          for (let i = data.feeds.length - 1; i >= 0; i--) {
            const feed = data.feeds[i];
            const value = parseFloat(feed[`field${fieldNumber}`]); // Convert to float
            const gmtDate = new Date(feed.created_at);

            // Simply use the local time directly (no conversion)
            const localTime = gmtDate.toLocaleTimeString(); // Format as local time string

            // Ensure value is not null and include `0.00` as valid
            if (!isNaN(value)) {
              // Use the local time string for display
              labels.unshift(localTime);
              fieldData.unshift(value);
            }
          }

          // Get the color values from acolor
          const lineColor = acolor[fieldNumber]?.lineColor || 'orange'; // Default to orange
          const areaColor = acolor[fieldNumber]?.areaColor || 'rgba(255, 165, 0, 0.2)'; // Default to light orange

          // Set chart data with all labels and values
          setChartData({
            labels,
            datasets: [
              {
                label: `Field${fieldNumber} Data`,
                data: fieldData,
                borderColor: lineColor, // Line color from acolor prop
                backgroundColor: areaColor, // Area fill color from acolor prop
                fill: "start", // Specify the fill direction ("start", "end", "origin")
                tension: 0.3, // Smooth line
              },
            ],
          });
        } else {
          console.log("No feeds found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data immediately and set up interval for repeated fetching
    fetchData();
    const interval = setInterval(fetchData, 3000); // Fetch data every 3 seconds

    // Cleanup the interval when component unmounts
    return () => clearInterval(interval);
  }, [fieldNumber, acolor]); // Add fieldNumber and acolor as dependencies

  return (
    <div className="card">
      <div className="leakage-chart-title">Leakage Current</div>
      {chartData ? (
        <Line
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: { display: true, position: "top" }, // Display chart legend
            },
            scales: {
              x: {
                title: { display: true, text: "Time of Day" }, // X-axis label
                type: "category", // Ensuring the X axis is treated as categories
                labels: chartData.labels, // Use formatted labels for the time
                ticks: {
                  autoSkip: true, // Automatically skip unnecessary labels
                  maxTicksLimit: 10, // Limit the number of labels shown
                },
              },
              y: {
                title: { display: true, text: "Value" }, // Y-axis label
                ticks: {
                  beginAtZero: true, // Ensure Y-axis starts at 0
                },
              },
            },
          }}
        />
      ) : (
        <p>Loading chart...</p> // Show loading text while data is being fetched
      )}
    </div>
  );
}

export default ChartPage;
