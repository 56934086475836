import React, { useEffect, useState } from "react";
import ChartPage from "../../Chart/Chart";
import MapPage from "../../Map/Map";
import WeatherPage from "../../Weather/WeatherPage";
import LightningPage from "../LightningPage/LightningPage"; // Import LightningPage
import '../Dynamic.css';
import './location.css';

const areaColor = {
  1: { lineColor: "rgb(238, 160, 25)", areaColor: "rgba(247, 237, 102, 0.2)" },
  2: { lineColor: "rgb(100, 105, 254)", areaColor: "rgba(69, 95, 242, 0.2)" },
  3: { lineColor: "rgba(16, 201, 16, 1)", areaColor: "rgba(16, 201, 16, 0.2)" },
};

function LocationBlock({ locationCoordinates, locationName, fieldNumber }) {
  const [latestReading, setLatestReading] = useState(null);
  const [timeSinceLastReading, setTimeSinceLastReading] = useState(null);
  const [showLightningPage, setShowLightningPage] = useState(false); // Controls visibility of LightningPage
  const [lightningAlertDismissed, setLightningAlertDismissed] = useState(false); // Tracks if alert was dismissed
  const [lastTriggerTime, setLastTriggerTime] = useState(null); // Stores the time when alert was last triggered

  // Logic to reset the alert trigger after 20 seconds
  const resetAlertTrigger = () => {
    setLightningAlertDismissed(false);
    setLastTriggerTime(null); // Reset last trigger time
  };

  useEffect(() => {
    const fetchLatestFeed = async () => {
      try {
        const response = await fetch(
          "https://api.thingspeak.com/channels/2740476/feeds.json?api_key=IN5SG9ZMXCBXQD5L&results=90"
        );
        const data = await response.json();
  
        if (data.feeds && data.feeds.length > 0) {
          let latestNonNullValue = null;
          let latestTimestamp = null;
  
          for (let i = data.feeds.length - 1; i >= 0; i--) {
            const feed = data.feeds[i];
            const value = parseFloat(feed[`field${fieldNumber}`]);
            const gmtDate = new Date(feed.created_at);
  
            if (!isNaN(value)) {
              latestNonNullValue = value;
              latestTimestamp = gmtDate;
              break;
            }
          }
          if (latestNonNullValue !== null) {
            setLatestReading(Math.round(latestNonNullValue)); // Set the latest reading dynamically
          } else {
            setLatestReading("No valid data");
          }
  
          if (latestTimestamp !== null) {
            const currentDate = new Date();
            const feedDate = new Date(latestTimestamp);
            const timeDifference = currentDate - feedDate;
            const seconds = Math.floor(timeDifference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);
  
            let timePassed = "";
  
            if (seconds < 60) {
              timePassed = `${seconds} seconds ago`;
            } else if (minutes < 60) {
              timePassed = `${minutes} minutes ago`;
            } else if (hours < 24) {
              timePassed = `${hours} hours ago`;
            } else {
              timePassed = `${days} days ago`;
            }
  
            setTimeSinceLastReading(timePassed); // Update the time dynamically
          }
  
          // Check if lightning alert can be triggered
          const triggerThreshold = 19;
          const currentTime = new Date();
  
          const canTriggerAlert =
            lightningAlertDismissed ||
            !lastTriggerTime ||
            currentTime - lastTriggerTime > 20000;
  
          if (latestNonNullValue > triggerThreshold && canTriggerAlert) {
            setShowLightningPage(true);
            setLastTriggerTime(currentTime); // Set last trigger time
            setLightningAlertDismissed(false);
  
            setTimeout(() => {
              setShowLightningPage(false); // Reset the alert visibility after 1 hour
            }, 3600000); // 1 hour
          }
        }
      } catch (error) {
        console.error("Error fetching latest feed:", error);
      }
    };
  
    // Call fetch function immediately and then set an interval to refetch the data every 3 seconds
    fetchLatestFeed();
    const interval = setInterval(fetchLatestFeed, 3000); // Fetch data every 3 seconds
  
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [fieldNumber, lightningAlertDismissed, lastTriggerTime]);
  

  return (
    <div className="location-block">
      <div className="ch1-title">
        <h2>{locationName}</h2>
      </div>
      <div className="dyn-content">
        {/* {showLightningPage && (
          <div className="lightning-page">
            <LightningPage
              location={locationName}
              lastReading={latestReading}
              onDismiss={() => {
                setShowLightningPage(false); // Dismiss the page
                setLightningAlertDismissed(true); // Mark as dismissed
                setTimeout(resetAlertTrigger, 20000); // Reset alert after 20 seconds
              }}
            />
          </div>
        )} */}
        <div className="chart-container">
          <ChartPage fieldNumber={fieldNumber} acolor={areaColor} />
        </div>
        <div className="info-section">
          <div className="left-side">
            <div className="info-box">
              <h3>Latest Reading</h3>
              <h1>{latestReading !== null ? `${latestReading} mA` : "Loading..."}</h1>
              <h6>Time Since Last Reading</h6>
              <h6>{timeSinceLastReading || "Loading..."}</h6>
            </div>
            <div className="info-box">
              <h3>Location Map</h3>
              <MapPage locationProp={locationCoordinates} />
            </div>
          </div>
          <div className="right-side">
            <div className="info-box">
              <h3>Weather</h3>
              <WeatherPage locationProp={locationCoordinates} field={fieldNumber} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationBlock;
