import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import adminRoutes from "./routes/adminRoutes"; // Admin-specific routes
import headRoutes from "./routes/headRoutes";   // Head-specific routes
import userRoutes from "./routes/userRoutes";   // User-specific routes
import withTracker from "./withTracker";
import Background from "components/account/assets/background/2.jpg";
import "bootstrap/dist/css/bootstrap.min.css";

const Account = (props) => {
  const { role, location, sensor } = props;
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const browserhistory = createBrowserHistory();

  // Handle redirection based on role, location, and sensor
  useEffect(() => {
    if (!role || !location || !sensor) {
      setIsLoading(false); // Stop loading if props are not available
      return;
    }

    if (role === "Admin") {
      browserhistory.push("/Dynamic"); // Redirect Admin to Dashboard
    } else if (role === "User" && sensor === "Leakage Sensor") {
      switch (location) {
        case "Kuanrmunda":
          browserhistory.push("/kaurmunda");
          break;
        case "Bhojpur":
          browserhistory.push("/bhojpur");
          break;
        case "Turunga":
          browserhistory.push("/turunga");
          break;
        default:
          browserhistory.push("/unknown");
      }
    }

    setIsLoading(false); // Stop loading after redirection logic
  }, [role, location, sensor, browserhistory]);

  // Determine which routes to use based on the role
  let routes = [];
  if (role === "Admin") {
    routes = adminRoutes;
  } else if (role === "Head") {
    routes = headRoutes;
  } else if (role === "User") {
    routes = userRoutes;
  }

  // Show a loader while checking props and performing redirection
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME || ""} history={browserhistory}>
      <div
        style={{
          fontFamily: "Times New Roman",
          backgroundImage: `url(${Background})`,
          minHeight: "100vh",
          height: "100%",
          backgroundPosition: "center",
          backgroundSize: "auto",
          backgroundRepeat: "repeat",
        }}
      >
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker((props) => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              })}
            />
          );
        })}
      </div>
    </BrowserRouter>
  );
};

export default Account;