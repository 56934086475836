import { passwordReset } from './requests';
import {createAccount} from "./requests";
// Generic function to help parse Firebase error messages
const parseErrorMessage = (errorMessage) => {
  return errorMessage.split(' ')[0].replace(/_/g, ' ').toLowerCase();
};

// Callback for successful sign up
const signUpSuccessCallback = (data, setNotice) => {
  let responseMessage = 'A verification email has been sent to the email address provided.';
  let responseHeader = 'Account created';

  if (data.error) {
    responseHeader = parseErrorMessage(data.error);

    switch (data.error) {
      case 'EMAIL_EXISTS':
        responseMessage = 'The email address provided already exists.';
        break;

      case 'USERNAME_EXISTS':
        responseMessage = 'The username provided already exists.';
        break;
      default:
        responseMessage = 'Sign in attempt failed.';
    }
  }
 return setNotice({
    header: responseHeader,
    message: responseMessage
  });

};

const loginSuccessCallback = (data, setNotice) => {
  if (data.error) {
    let responseMessage;
    const [ errorMessage ] = data.error.split(' ');

    switch (errorMessage) {
      case 'INVALID_PASSWORD':
        responseMessage = 'The password provided does not match email or username provided, try again or reset your password.';
        break;

      case 'MISSING_EMAIL':
        responseMessage = 'The email or username provided does not match an account, try again or sign up.';
        break;

      case 'TOO_MANY_ATTEMPTS_TRY_LATER':
        responseMessage = 'This account has been temporarily disabled due to several failed login attempts. You can reset your password or try again later.';
        break;
      default:
        responseMessage = 'Unable to log in with the information supplied.';
    }

    return setNotice({
      header: 'Login failed',
      message: responseMessage
    });
  }

  return data;
};


const passwordResetSuccessCallback = (data, setNotice) => {
  let responseHeader = 'Reset requested';
  let responseMessage = 'A password reset email has been sent to the email address provided.';
  if (data.error) {
    responseHeader = parseErrorMessage(data.error);
    switch (data.error) {
      case 'EMAIL_NOT_FOUND':
        responseMessage = 'The email address provided does not belong to an account.';
        break;

      default:
        responseMessage = 'Cannot reset password for the email address provided.';
    }
  }

  setNotice({
    header: responseHeader,
    message: responseMessage
  });
};

const errorCallback = (error, setNotice) => {
  console.error(error);
  setNotice({
    header: 'Server error',
    message: 'Failed due to internal server error.'
  });
};

const submitForm = (pageType, email, password, firstName, lastName, username, role, site, type, setNotice) => {

  return new Promise((resolve, reject) => {
    switch (pageType) {
      case 'signUp':
        createAccount(
          email,
          password,
          firstName,
          lastName,
          username,
          role,
          site,  // Pass site as data
          type,  // Pass type as data
          (data) => resolve(signUpSuccessCallback(data, setNotice)),  // Handle success
          (error) => reject(errorCallback(error, setNotice))  // Handle error
        );
        return resolve();



      case 'passwordReset':
        return passwordReset(
          email,
          (data) => resolve(passwordResetSuccessCallback(data, setNotice)),
          (error) => reject(errorCallback(error, setNotice))
        );
      default:
        console.error('Submit failed');
        return resolve();
    }
  });
};

export default submitForm;